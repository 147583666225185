import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import blueDownArrow from "../../img/blue-down-arrow.svg";
import OrangeDownArrow from "../../img/orange-down-arrow.svg";
import greyDownArrow from "../../img/grey-down-arrow.svg";
import SignInButton from "../../components/Common/SignInButton";
import GetDemoNavButton from "../../components/Common/GetDemoNavBtn";
import WebAutomation from "../../img/home/menu/platform/web-automation-testing.svg";
import MobileAutomation from "../../img/home/menu/platform/mobile-automation.svg";
import ApiAutomation from "../../img/home/menu/platform/api-testing.svg";
import Ai from "../../img/home/menu/platform/fireflink-ai.svg";
import ManualTest from "../../img/home/menu/platform/manual-test-case-management.svg";
import Defect from "../../img/home/menu/platform/defect-management.svg";
import UiTest from "../../img/home/menu/platform/ui-performance-testing.svg";
import VisualTesting from "../../img/home/menu/platform/visual-testing.svg";
import MobileLocator from "../../img/home/menu/finder/mobile-locator-finder.svg";
import MsLocator from "../../img/home/menu/finder/ms-dynamic-locator.svg";
import SalesForceLocator from "../../img/home/menu/finder/sales-force-locator.svg";
import WebLocatorFinder from "../../img/home/menu/finder/web-locator.svg";
import DomainAssets from "../../img/home/menu/assets/domain-assets.svg";
import TemplateStore from "../../img/home/menu/assets/template-store-assets.svg";
import PlatformAssets from "../../img/home/menu/assets/platform-assets.svg";
import CloudLive from "../../img/home/menu/cloud/cloud-live.svg";
import CloudMobile from "../../img/home/menu/cloud/cloud-mobile.svg";
import CloudWeb from "../../img/home/menu/cloud/cloud-web.svg";

import MsDynamics from "../../img/home/menu/solutions/ms.svg";
import Oracle from "../../img/home/menu/solutions/oracle.svg";
import PeopleSoft from "../../img/home/menu/solutions/people-soft.svg";
import SalesForce from "../../img/home/menu/solutions/salesforce.svg";
import SapAutomation from "../../img/home/menu/solutions/sap.svg";
import ServiceNow from "../../img/home/menu/solutions/servicenow.svg";
import WorkDayAutomation from "../../img/home/menu/solutions/workday-seeklogo.svg";

import Blogs from "../../img/home/menu/resources/blog.svg";
import CaseStudy from "../../img/home/menu/resources/case-studies.svg";
import Documentation from "../../img/home/menu/resources/documentation.svg";
import PreRequites from "../../img/home/menu/resources/prerequisites.svg";
import ReleaseNotes from "../../img/home/menu/resources/release-notes.svg";
import Support from "../../img/home/menu/resources/support.svg";
import VideoTutorials from "../../img/home/menu/resources/video-tutorials.svg";

function DropDownMenu({ userLogin }) {
  return (
    <>
      <div className="res_menu_btn">
        <div>
          <GetDemoNavButton />
        </div>
        {userLogin ? (
          <></>
        ) : (
          <>
            <div>
              <SignInButton path="/signin" />
            </div>
          </>
        )}
      </div>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header className="accordianNavBtn">
            <span>Products</span>
            <img src={OrangeDownArrow} alt="Orange Down Arrow" />
          </Accordion.Header>

          <Accordion.Body className="res_menu_body">
            {/* Nested Accordion */}
            <Accordion defaultActiveKey="0" className="prodOne" flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordianNavBtn prodOneHeading">
                  <Link to="/fireflink-platform">FireFlink Platform</Link>
                  <img src={blueDownArrow} alt="blueDownArrow" />
                </Accordion.Header>
                <Accordion.Body>
                  <div className="prodContent">
                    <Link to="/fireflink-platform/web-automation-testing">
                      <div className="prodOneContent">
                        <div className="w35">
                          <img src={WebAutomation} alt="Web Automation" />
                        </div>
                        <div>
                          <h3>Web Automation Testing</h3>
                          <p>Rapid Web Testing Platform</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-platform/visual-testing">
                      <div className="prodOneContent">
                        <div className="w35">
                          <img src={VisualTesting} alt="Visual Testing" />
                        </div>
                        <div>
                          <h3>Visual Testing</h3>

                          <p>Performance Test for Optimized UX</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-platform/manual-test-case-management">
                      <div className="prodOneContent">
                        <div className="w35">
                          <img src={ManualTest} alt="Manual Test" />
                        </div>
                        <div>
                          <h3>Manual Test Case Management</h3>

                          <p>Performance Test for Optimized UX</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-platform/mobile-app-automation-testing">
                      <div className="prodOneContent">
                        <div className="w35">
                          <img src={MobileAutomation} alt="Mobile Automation" />
                        </div>
                        <div>
                          <h3>Mobile Automation Testing</h3>
                          <p>Native & Hybrid App Testing</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-platform/ui-performance-testing">
                      <div className="prodOneContent">
                        <div className="w35">
                          <img src={UiTest} alt="Ui Test" />
                        </div>
                        <div>
                          <h3>UI Performance Testing</h3>
                          <p>Finest App "Feel" with UI Testing</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-platform/defect-management">
                      <div className="prodOneContent">
                        <div className="w35">
                          <img src={Defect} alt="Defect" />
                        </div>
                        <div>
                          <h3>Defect Management</h3>

                          <p>Custom Templates for Defect Management</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-platform/api-automation-testing">
                      <div className="prodOneContent b_none">
                        <div className="w35">
                          <img src={ApiAutomation} alt="Api Automation" />
                        </div>
                        <div>
                          <h3>API Automation Testing</h3>
                          <p>For Enhanced API Test Coverage</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/ai-automation-testing-tool">
                      <div className="prodOneContent b_none">
                        <div className="w35">
                          <img className="ai_img_menu" src={Ai} alt="Ai" />
                        </div>
                        <div>
                          <h3>AI Powered Automation Testing</h3>
                          <p>Dive into the world of AI driven testing</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="prodTwo" flush>
              <Accordion.Item eventKey="1">
                <Accordion.Header className="accordianNavBtn prodTwoHeading">
                  <Link to="/fireflink-finder">FireFlink Finder</Link>
                  <img src={blueDownArrow} alt="blueDownArrow" />
                </Accordion.Header>
                <Accordion.Body>
                  <div className="prodContent">
                    <Link to="/fireflink-finder/web-locator-finder">
                      <div className="prodTwoContent">
                        <div className="w35">
                          <img src={WebLocatorFinder} alt="WebLocator Finder" />
                        </div>
                        <div>
                          <h3>Web Locator Finder</h3>
                          <p>Hassle-free Tool for Optimized Locators</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-finder/msdynamics-locator-finder">
                      <div className="prodTwoContent">
                        <div className="w35">
                          <img src={MsLocator} alt="Ms Locator" />
                        </div>
                        <div>
                          <h3>MS Dynamics Locator Finder</h3>
                          <p>MS Dynamic Apps UI Element Locator</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-finder/salesforce-locator-finder">
                      <div className="prodTwoContent">
                        <div className="w35">
                          <img
                            src={SalesForceLocator}
                            alt="SalesForce Locator"
                          />
                        </div>
                        <div>
                          <h3>Salesforce Locator Finder</h3>
                          <p>Salesforce-Exclusive Element Finder</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-finder/mobile-locator-finder">
                      <div className="prodTwoContent b_none">
                        <div className="w35">
                          <img src={MobileLocator} alt="Mobile ocator" />
                        </div>
                        <div>
                          <h3>Mobile Locator Finder</h3>
                          <p>Synchronous Android/iOS Element Finder</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="prodThree" flush>
              <Accordion.Item eventKey="2">
                <Accordion.Header className="accordianNavBtn prodThreeHeading">
                  <Link to="/fireflink-assets">FireFlink Assets</Link>
                  <img src={blueDownArrow} alt="blueDownArrow" />
                </Accordion.Header>
                <Accordion.Body>
                  <div className="prodContent">
                    <Link to="/fireflink-assets/#platform_assets">
                      <div className="prodThreeContent">
                        <div className="w35">
                          <img src={PlatformAssets} alt="Platform Assets" />
                        </div>
                        <div>
                          <h3>Platform Assests</h3>
                          <p>
                            Accelerate Business Platform Application Testing
                          </p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-assets/#domain_assets">
                      <div className="prodThreeContent">
                        <div className="w35">
                          <img src={DomainAssets} alt="Domain Assets" />
                        </div>
                        <div>
                          <h3>Domain Assests</h3>
                          <p>Off-The-Shelf Test Cases for any Domain</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-assets/#template_store">
                      <div className="prodThreeContent b_none">
                        <div className="w35">
                          <img src={TemplateStore} alt="Template Store" />
                        </div>
                        <div>
                          <h3>Template Store</h3>
                          <p>Buy and Sell Templates With Asset Store</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Accordion className="prodFour" flush>
              <Accordion.Item eventKey="3">
                <Accordion.Header className="accordianNavBtn prodFourHeading">
                  <Link to="/fireflink-cloud">FireFlink Cloud</Link>
                  <img src={blueDownArrow} alt="blueDownArrow" />
                </Accordion.Header>
                <Accordion.Body>
                  <div className="prodContent">
                    <Link to="/fireflink-cloud/#cloud-mobile">
                      <div className="prodFourContent">
                        <div className="w35">
                          <img src={CloudMobile} alt="Cloud Mobile" />
                        </div>
                        <div>
                          <h3>Cloud Mobile</h3>

                          <p>Test Concurrently across 1000s of Devices</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-cloud/#cloud-web">
                      <div className="prodFourContent">
                        <div className="w35">
                          <img src={CloudWeb} alt="Cloud Web" />
                        </div>
                        <div>
                          <h3>Cloud Web</h3>
                          <p>Universal Compatibility with Browsers and OSes</p>
                        </div>
                      </div>
                    </Link>
                    <Link to="/fireflink-cloud/#cloud-live">
                      <div className="prodFourContent b_none">
                        <div className="w35">
                          <img src={CloudLive} alt="Cloud Live" />
                        </div>
                        <div>
                          <h3>Cloud Live Testing</h3>
                          <p>
                            Live Tests with Minimal Efforts across Environments
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header className="accordianNavBtn">
            <span>Solutions</span>
            <img src={OrangeDownArrow} alt="Orange Down Arrow" />
          </Accordion.Header>
          <Accordion.Body className="res_menu_body">
            <div className="solutionContent">
              <Link to="/solution/salesforce-automation-testing">
                <div className="solOneContent">
                  <div className="w35">
                    <img src={SalesForce} alt="SalesForce" />
                  </div>
                  <div>
                    <h3>SaleForce</h3>
                    <p>End to End Salesforce testing ensured</p>
                  </div>
                </div>
              </Link>
              <Link to="/solution/msdynamics-automation-testing">
                <div className="solOneContent">
                  <div className="w35">
                    <img src={MsDynamics} alt="MS Dynamics" />
                  </div>
                  <div>
                    <h3>MS Dynamics</h3>
                    <p>Complex system testing handled consistently</p>
                  </div>
                </div>
              </Link>
              <Link to="/solution/sap-automation-testing">
                <div className="solOneContent">
                  <div className="w35">
                    <img src={SapAutomation} alt="Sap Automation" />
                  </div>
                  <div>
                    <h3>SAP</h3>
                    <p>Off the shelf assets for SAP</p>
                  </div>
                </div>
              </Link>
              <Link to="/solution/oracle-automation-testing">
                <div className="solOneContent">
                  <div className="w35">
                    <img src={Oracle} alt="Oracle" />
                  </div>
                  <div>
                    <h3>Oracle</h3>
                    <p>A unified solution for quality test outcome</p>
                  </div>
                </div>
              </Link>
              <Link to="/solution/service-now-automation-testing">
                <div className="solOneContent">
                  <div className="w35">
                    <img src={ServiceNow} alt="Service Now" />
                  </div>
                  <div>
                    <h3>Service Now</h3>
                    <p>
                      Rapid agile adaptability for your ServiceNow applications
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/solution/workday-automation-testing">
                <div className="solOneContent">
                  <div className="w35">
                    <img src={WorkDayAutomation} alt="WorkDay Automation" />
                  </div>
                  <div>
                    <h3>Work Day</h3>
                    <p>
                      {" "}
                      Commit to the depth of test coverage of your workday based
                    </p>
                  </div>
                </div>
              </Link>
              <Link to="/solution/peoplesoft-automation-testing">
                <div className="solOneContent b_none">
                  <div className="w35">
                    <img src={PeopleSoft} alt="People Soft" />
                  </div>
                  <div>
                    <h3>PeopleSoft</h3>
                    <p>
                      Exclusive assets library for PeopleSoft platform testing
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header className="accordianNavBtn accPricing">
            <Link to="/fireflink-platform-pricing">Pricing</Link>
          </Accordion.Header>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header className="accordianNavBtn">
            <span>Resources</span>
            <img src={OrangeDownArrow} alt="Orange Down Arrow" />
          </Accordion.Header>
          <Accordion.Body className="res_menu_body">
            <div className="resourcesContent">
              <Link to="/video-tutorial">
                <div className="resOneContent">
                  <div className="w35">
                    <img src={VideoTutorials} alt="Video Tutorials" />
                  </div>
                  <div>
                    <h3>Video Tutorials</h3>
                    <p>Simplified and Engaging Video Courses</p>
                  </div>
                </div>
              </Link>
              <Link to="/documentation">
                <div className="resOneContent">
                  <div className="w35">
                    <img src={Documentation} alt="Documentation" />
                  </div>
                  <div>
                    <h3>Documentation</h3>
                    <p>The Complete FireFlink Platform User Guide</p>
                  </div>
                </div>
              </Link>
              <Link to="/case-studies">
                <div className="resOneContent">
                  <div className="w35">
                    <img src={CaseStudy} alt="Case Study" />
                  </div>
                  <div>
                    <h3>Case Studies</h3>
                    <p>Insightful Software Testing Case Studies</p>
                  </div>
                </div>
              </Link>
              <Link to="/release-notes/latest-version">
                <div className="resOneContent">
                  <div className="w35">
                    <img src={ReleaseNotes} alt="Release Notes" />
                  </div>
                  <div>
                    <h3>Release Notes</h3>
                    <p>Latest Enhancements and Feature Updates</p>
                  </div>
                </div>
              </Link>
              <Link to="/blogs">
                <div className="resOneContent">
                  <div className="w35">
                    <img src={Blogs} alt="Blogs" />
                  </div>
                  <div>
                    <h3>Blogs</h3>
                    <p>Fascinating Blogs on Software Testing</p>
                  </div>
                </div>
              </Link>
              <Link to="http://support.fireflink.com" target="_blank">
                <div className="resOneContent">
                  <div className="w35">
                    <img src={Support} alt="Support" />
                  </div>
                  <div>
                    <h3>Support</h3>
                    <p>Have a concern? Reach out to us now</p>
                  </div>
                </div>
              </Link>
              <Link to="/prerequisites/cloud-version">
                <div className="resOneContent b_none">
                  <div className="w35">
                    <img src={PreRequites} alt="Pre Requites" />
                  </div>
                  <div>
                    <h3>Prerequisites</h3>
                    <p>
                      {" "}
                      System requirements for FireFlink Platform Client and
                      Server setup{" "}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}

export default DropDownMenu;
